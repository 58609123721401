import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'

const NotFoundPage = () => (
  <Layout>
    <Header
      title="Oops...Ainda não estou EMMIM..."
      subtitle="...porque esta página não existe"
    />
    
    <Contact title="...contudo pode juntar-se à nossa comunidade" subtitle="e publicar o seu artigo no nosso blog"/>
    <SocialLinks />
    <Footer />
  </Layout>
);


export default withPrismicUnpublishedPreview(NotFoundPage)

